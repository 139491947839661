.entry-container {
  border: 5px solid #16191e;
  background-color: #21252c;
  margin-bottom: 1.5em;
  text-align: center;
}

.entry-container:last-child {
  margin-bottom: 0;
}

.entry-container div {
  padding: 12px;
}

.project-title p {
  color: rgb(198, 186, 20);
  font-size: 1.6em;
}

.project-description {
  color: #fff;
  font-size: 1.1em;
}

.project-link a {
  font-size: 0.9em;
  outline: none;
  text-decoration: none;
  padding: 2px 1px 0;
}

.project-link a:link,
.project-link a:visited {
  color: rgb(32, 121, 255);
}

.project-link a:focus,
.project-link a:hover,
.project-link a:active {
  border-bottom: 1px solid rgb(32, 121, 255);
}

/* Adds an external link icon "after" the link if href starts with these values */
.project-link a[href^="http"]::after,
.project-link a[href^="https://"]::after
{
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' stroke='rgb(32, 121, 255)' fill-opacity='1' stroke-opacity='1' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z'/%3E%3Cpath fill-rule='evenodd' d='M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z'/%3E%3C/svg%3E");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
