.app-header {
  background-color: #2a2e33;
  flex-shrink: 0;
  padding: 1em;
}

.app-header a {
  font-size: 1.5em;
}

.app-header a:link,
.app-header a:visited,
.app-header a:hover,
.app-header a:active {
  color: #dfe4e6;
  text-decoration: none;
}
