.app-footer {
  background-color: #2a2e33;
  flex-shrink: 0;
  padding: 0.5em;
  text-align: center;
}

.app-footer p {
  font-family: cursive;
  font-size: 1em;
}
