.app {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
  margin: 25px;
}

.section-wrapper {
  background-color: #2a2e33;
  margin-bottom: 25px;
}

.section-wrapper:last-child {
  margin-bottom: 0;
}

.section-header {
  background-color: #21252c;
  font-size: 1.25em;
  padding: 0.75em;
}

h1,
h2,
p {
  margin: 0;
}

/* When the browser is at least 1600px and above */
@media screen and (min-width: 1600px) {
  main {
    margin: 25px 10%;
  }
}

/* When the browser is at least 2200px and above */
@media screen and (min-width: 2200px) {
  main {
    margin: 25px 20%;
  }
}
